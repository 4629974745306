import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "threedotts | Desenvolvimento de Websites", // e.g: 'Name | Developer'
  lang: "pt", // e.g: en, es, fr, jp
  description: "Bem-vindo ao nosso website", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Oi, nós somos a",
  name: "threedotts",
  subtitle: "Seja bem-vindo ao nosso website",
  cta: "Saber mais",
};

// ABOUT DATA
export const aboutData = {
  img: "profile.jpg",
  paragraphOne:
    " Na threedotts, nós entendemos como é importante criar um impacto visual duradouro. Os nossos serviços de Web design estão disponíveis e são personalizáveis para atender às suas necessidades.",
  paragraphTwo:
    "Iremos ajudar a colocar sua marca um passo à frente da concorrência.",
  paragraphThree:
    "Entre em contacto connosco hoje para descobrir como podemos criar o seu website e dar o impulso que ele exige.",
  resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "perola.png",
    title: "Pérola inc.",
    info: "A Pérola inc. é a maior loja de vendas de bijuterias online da cidade de Maputo. Os seus produtos eram vendidos através das redes socias.",
    info2:
      "E agora, com os nossos serviços web, podem também ser encontrados na sua loja online.",
    url: "",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "crwn.png",
    title: "Crwn Shopy",
    info: "Uma template de uma loja online desenvolvida por nós com propróstio de demostração de diferentes designs",
    info2:
      "Pode baixar o código fonte e modificar e fazer o uso do template ao seu gosto",
    url: "https://crwn-shopy.herokuapp.com",
    repo: "https://github.com/Lima-Tembe/crwn-clothing", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "2001.png",
    title: "Esplanada 2001",
    info: "Uma esplanada que contem restaurante, bar, discoteca, salão e um hotel.",
    info2: "Situada na Costa do Sol 1Km depois do Bairro dos pescadores",
    url: "",
    repo: "", // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "Com a tecnologia como uma grande força motriz nos negócios hoje, a necessidade de uma marca forte e uma estratégia de design digital tornou-se mais importante do que nunca. E nós como Web Designers iremos colocar o seu negócio online.",
  btn: "Entrar em contacto",
  email: "threedotts.inc@gmail.com",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "instagram",
      url: "https://www.instagram.com/threeedotts",
    },
    {
      id: nanoid(),
      name: "whatsapp",
      url: "https://wa.me/+258846673544?text=Boa%20tarde!",
    },
    {
      id: nanoid(),
      name: "facebook",
      url: "https://web.facebook.com/threedotts.inc",
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
